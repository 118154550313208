<template>
    <div>
        <b-overlay :show="loading">
            <b-card>
                <h4 class="card-title">Nueva Imágen</h4>
                <validation-observer ref="imageRules">
                    <b-form>
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                >
                                    <h5 class="font-weight-bold">
                                        Imagen
                                    </h5>

                                            <validation-provider
                                                    #default="{ errors }"
                                                    rules="required"
                                                    name="Imagen"
                                            >
                                                <b-form-file
                                                        v-model="image"
                                                        placeholder="Seleccione un archivo o suéltelo aquí..."
                                                        drop-placeholder="Suelte el archivo aquí..."

                                                        accept="image/*"
                                                        browse-text="Seleccione"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>

                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                >
                                    <h5 class="font-weight-bold">
                                        Nombre
                                    </h5>
                                    <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="Nombre"
                                    >
                                        <b-form-input
                                                id="nombre"
                                                v-model="nombre"
                                                placeholder="Escriba el nombre de la imagen"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>

                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded></b-img>

                            </b-col>
                            <b-col md="12" class="text-right">
                                <div></div>
                                <b-button v-if=""
                                        variant="outline-primary"
                                        @click="cancelar"
                                        class="mr-1"
                                          :disabled="image == null && nombre == null"
                                >
                                    <feather-icon icon="XIcon" class="mr-50"/>
                                    <span class="align-middle">Cancelar</span>
                                </b-button>
                                <b-button
                                        variant="outline-primary"
                                        @click="insertar"
                                >
                                    <feather-icon icon="SaveIcon" class="mr-50"/>
                                    <span class="align-middle">Guardar</span>
                                </b-button>
                            </b-col>

                        </b-row>
                    </b-form>
                </validation-observer>
            </b-card>
            <b-card>
                <h4 class="card-title">Listado de Imágenes</h4>
                <div>
                    <b-row class="pl-1">
                        <b-col cols="12" class="my-1 text-right">
                            <b-form-group
                                    label="Buscar Imágen"
                                    label-for="filter-input"
                                    label-cols-sm="3"
                                    label-align-sm="right"
                                    label-size="sm"
                                    class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                            id="filter-input"
                                            v-model="filter"
                                            type="search"
                                            placeholder="Escriba nombre de la imagen"
                                            @input="onFiltered"
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button variant="outline-primary" :disabled="!filter" @click="filter = ''">
                                            <feather-icon icon="XIcon" class="mr-50"/>
                                            <span class="align-middle">Cancelar</span>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col cols="3" class="mt-2">
                            <div class="d-flex align-items-center mb-1 mb-md-0">
                                <label>Mostrar</label>
                                <b-form-select
                                        @change="getList"
                                        id="perPageSelect"
                                        v-model="perPage"
                                        :options="pageOptions"
                                        size="sm"
                                        class="mx-50 col-md-3"
                                ></b-form-select>
                                <label>filas por página</label>
                            </div>
                        </b-col>
                        <b-col cols="5">
                            <b-pagination
                                    @change="onPageChange"
                                    v-model="page"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="center"
                                    class="mt-2 mr-1"
                                    aria-controls="my-table"
                            />
                        </b-col>
                        <b-col cols="4" class="mt-2 pl-1">
                            <div class="d-flex justify-content-end pr-1">
                                <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                            </div>
                        </b-col>

                        <b-col cols="12">
                            <b-table
                                    striped
                                    hover
                                    small
                                    :per-page="perPage"
                                    :items="listado"
                                    :fields="fields"
                                    responsive
                                    class="mb-0"
                                    id="my-table"
                                    ref="table"
                                    show-empty
                            >
                                <template #cell()="data">
                                    {{ data.value ? data.value : '-' }}
                                </template>
                                <template #cell(url)="data">
                                    <b-link
                                            class="ml-25"
                                            :href="data.item.url"
                                            target="_blank"
                                    >url
                                    </b-link>
                                </template>
                                <template #cell(imagen)="data">
                                    <b-avatar square :src="data.item.url"></b-avatar>
                                </template>
                                <template #cell(accion)="data">
                                    <!-- Column: Action -->
                                    <div class="text-nowrap">
                                        <feather-icon
                                                v-b-tooltip.hover.v-primary="'Ver Imagen'"
                                                icon="ZoomInIcon"
                                                class="mr-1"
                                                @click="verImage(data.item.url)"


                                        />
                                    </div>
                                </template>
                                <template #empty="scope">
                                    <div class="text-center">No existen resultados</div>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </b-overlay>
        <b-modal
                id="modal-image"
                ref="modal_img"
                title="Imagen"
                ok-title="Cerrar"
                size="lg"
                ok-only>
            <div>
                <b-img :src="imageSelected" fluid alt="Responsive image"></b-img>
            </div>
        </b-modal>
        <div class="d-none">{{notificaciones}}</div>
    </div>
</template>

<script>
    const base64Encode = data =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    import {
        BTable,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BButton,
        BTooltip,
        BCardText,
        BAvatar,
        BOverlay,
        BFormFile,
        BImg,
        BFormInput,
        BLink,
        VBTooltip,
        BForm,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import store from "@/store";
    import {ValidationProvider, ValidationObserver,localize} from 'vee-validate'
    import es from 'vee-validate/dist/locale/es.json'
    import _ from 'lodash'
    export default {
        name: "Listar",
        components: {
            BTable,
            BBadge,
            BPagination,
            BRow,
            BCol,
            BButton,
            BTooltip,
            BCardText,
            BAvatar,
            BOverlay,
            BFormFile,
            BImg,
            BFormInput,
            BLink,
            ToastificationContent,
            ValidationProvider,
            ValidationObserver,
            localize,
            BForm,
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        data() {
            return {
                fields: [
                    {key: 'nombre', label: 'Nombre', display: false},
                    {key: 'imagen', label: 'Imagen ', display: true, tdClass: 'text-center', thClass: 'text-center'},
                    {key: 'accion', label: 'acciones', display: true, tdClass: 'text-center', thClass: 'text-center'},
                ],
                perPage: 50,
                page: 1,
                pageOptions: [50, 100, 500, 1000],
                image: null,
                imageSrc: null,
                imageDelete: false,
                imageSelected: null,
                nombre: null,
                filter: '',
                response: false,
            }
        },
        mounted() {
            localize('es', es)
            this.getList()
        },
        computed: {
            hasImage() {
                return !!this.imageSrc;
            },
            totalRows() {
                return this.$store.state.imagenes.rowsTotal;
            },
            to() {
                return this.$store.state.imagenes.to;
            },
            from() {
                return this.$store.state.imagenes.from;
            },
            urlImage() {
                return this.$store.state.imagenes.urlImage;
            },
            listado() {
                return this.$store.state.imagenes.list
                /*return [{
                    nombre: 'Edutecno',
                    url: 'https://blobcert.blob.core.windows.net/certificados/HtbKnbZMkvLECSTIm4kMpQuSB5zyhrlYd36rnUj4.png',
                    imagen:'https://blobcert.blob.core.windows.net/certificados/HtbKnbZMkvLECSTIm4kMpQuSB5zyhrlYd36rnUj4.png'
                }]*/

            },
            loading() {
                return this.$store.state.imagenes.loading;
            },
            notificaciones() {
                if (this.response && this.listado ) {
                    //this.showToast('success', 'La imagen se ha insertado correctamente')
                    this.response = false
                    this.cancelar()
                }


            },
        },
        methods: {

            getList() {
                let filterData = {
                    'page': this.page,
                    'perPage': this.perPage,
                    'filter': this.filter,
                }
                this.$store.dispatch('imagenes/getList', filterData)
            },
            cancelar() {
               this.nombre = null
               this.image = null

            },
            insertar(objeto) {
                objeto.preventDefault()
                this.$refs.imageRules.validate().then(success => {
                    if (success) {
                        let data = {
                            'nombre': this.nombre,
                            'file': this.image
                        }
                        this.$store.dispatch('imagenes/insertar', data)
                        this.response = true
                    }
                })
            },
            onPageChange(page) {
                this.page = page;
                this.getList();
            },
            onChangePerPage() {
                this.page = 1;
                this.getList();
            },
            verImage(image) {
                this.imageSelected = image
                this.$refs.modal_img.show();
            },
            showToast(variant, mensaje) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notificación',
                        icon: 'BellIcon',
                        text: mensaje,
                        variant,
                    },
                })
            },
            onFiltered:_.debounce(function (filter) {
                    this.getList()
            }, 800),
        },

        created() {

        },
        watch: {
            image(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        base64Encode(newValue)
                            .then(value => {
                                this.imageSrc = value;
                                let file = {'file': [this.image]}

                            })
                            .catch(() => {
                                this.imageSrc = null;
                            });
                    } else {
                        this.imageSrc = null;
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>
